<!--<template>
  <b-card title="Manage Promo Code">
    <b-col cols="12">
      <div class="d-flex justify-content-end">
        <b-button v-if="permission.add" @click="toCreateCampaign" variant="primary"
          >Create Batch Code</b-button
        >
      </div>
    </b-col>

    <b-col cols="12">
      <b-table
           :fields="campaignFields" 
            :items="batchPromoCode" 
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty>
        <template #cell(actions)="row">
          <b-button v-if="permission.edit" @click="toEditBatch(row.item._id)" class="mr-1" variant="primary">
            <feather-icon icon="EditIcon"/>
          </b-button>
          <b-button v-if="permission.delete" class="hidden" variant="danger">
                <feather-icon icon="Trash2Icon"/></b-button>
        </template>
      </b-table>
    </b-col>
  </b-card>
</template> -->

<template>
  <b-card title="Manage Promo Code">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <!-- <b-button v-if="permission.add" @click="toCreateCampaign" variant="primary">Create Batch Code</b-button> -->
        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table 
            :items="batchPromoCode"
            :fields="campaignFields" 
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            show-empty
          >
            <template #cell(actions)="row">
              
              <b-dropdown  id="dropdown-dropleft" variant="primary" size="sm" text="Menu" no-caret>
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item v-if="permission.edit">
                  <b-button @click="toEditBatch(row.item._id)" class="mr-1" variant="outline-primary">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item v-if="permission.delete">
                  <!--delete?-->
                  <b-button class="hidden" variant="danger">
                    <feather-icon icon="Trash2Icon"/>
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="batchPromoCode.length"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>

    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {userAccess} from '@/utils/utils'

export default {
  data() {
    return {
      campaignFields: [
        { key: "batch", label: "Batch" },
        { key: "campaign.name", label: "Campaign Name",filterable: true, sortable: true },
        { key: "status", label: "Status",filterable: true, sortable: true },
        { key: "actions", label: "Actions" },
      ],
      search: '',
      currentPage: 1,
      perPage: 5,
    };
  },
  mounted() {
    this.getBatchsPromocode(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      getBatchsPromocode: "pcode/getBatchsPromocode",
    }),
    toCreateCampaign() {
      this.$router.push(`/promo/promocode/generate`);
    },
    toEditBatch(id) {
      this.$router.push(`/promo/promocode/edit/${id}`);
    },
  },
  computed: {
    ...mapState({
      batchPromoCode: (state) => {
        return state.pcode.batchPromoCode;
      },
    }),
    permission() {
      return userAccess("Promo Code", "warranty_master_menu");
    },
  },
  created() {
    document.title = 'Promo Code | RSP'
  },
};
</script>
